import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Storage } from '@aws-amplify/storage';
import {
  Button,
  FormActionWrapper,
  FormTutorInfoFields,
} from '~common/components';
import { validationSchemas } from '~common/utils';
import BackButton from '../backButton';

const TutorInfoPanel = ({ formData, onNextStep, onPrevStep }) => {
  const {
    trigger, handleSubmit, control, getValues, watch, setValue,
  } = useForm({
    defaultValues: formData, resolver: yupResolver(validationSchemas.tutorInfoPanelSchema),
  });

  return (
    <form onSubmit={handleSubmit(onNextStep)} noValidate>
      <FormTutorInfoFields
        control={control}
        getValues={getValues}
        watch={watch}
        trigger={trigger}
        setValue={setValue}
        Storage={Storage}
      />
      <FormActionWrapper>
        <BackButton onClick={onPrevStep} />
        <Button type="submit">Next</Button>
      </FormActionWrapper>
    </form>
  );
};

TutorInfoPanel.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
};

export default TutorInfoPanel;
