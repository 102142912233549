import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox, FormControlLabel, FormGroup, Grid,
} from '@mui/material';

import {
  FormTextField,
  Button,
  FormActionWrapper,
} from '~common/components';
import { validationSchemas } from '~common/utils';
import useStyles from './styles';

const AccountPanel = ({ formData, onSubmit }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control } = useForm(
    { defaultValues: formData, resolver: yupResolver(validationSchemas.accountPanelSchema) },
  );

  return (

    <form onSubmit={handleSubmit(onSubmit)} className={classes.formPanel} noValidate>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="firstName"
            control={control}
            inputProps={{
              label: 'First Name',
              autoFocus: true,
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="lastName"
            control={control}
            inputProps={{
              label: 'Last Name',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="emailAddress"
            control={control}
            inputProps={{
              label: 'Email Address',
              required: true,
              className: classes.emailInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="password"
            control={control}
            inputProps={{
              label: 'Password',
              type: showPassword ? 'text' : 'password',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="confirmPassword"
            control={control}
            inputProps={{
              label: 'Confirm',
              type: showPassword ? 'text' : 'password',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                  className={classes.checkbox}
                />
              )}
              className={classes.label}
              label="Show Password"
            />
          </FormGroup>
          <FormActionWrapper>
            <Button onClick={() => navigate('/')} variant="text" className={classes.backLink} disableRipple>
              Log in instead
            </Button>
            <Button type="submit">Next</Button>
          </FormActionWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

AccountPanel.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountPanel;
