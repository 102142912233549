import { useState } from 'react';
import { API } from '@aws-amplify/api';
import { config } from '~common/utils';

const useAgreementForm = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { apiName } = config;
    const path = config.path.agreement;
    const myInit = {
      headers: {},
      body: {},
    };
    let response;

    try {
      response = await API.post(apiName, path, myInit);
      window.location = response?.output?.agreementUrl;
    } catch (e) {
      setError('There has been an error submitting the form please contact an administrator.');
      return;
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubmit,
    error,
    loading,
  };
};

export default useAgreementForm;
