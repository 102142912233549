import PropTypes from 'prop-types';
import { Button } from '~common/components';
import useStyles from './styles';

const BackButton = ({ onClick, ...otherProps }) => {
  const { classes } = useStyles();

  return (
    <Button type="button" variant="text" onClick={onClick} className={classes.button} {...otherProps}>
      Back
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
