import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    margin: 'auto 0 auto -8px',
  },
  formPanel: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  label: {
    color: theme.palette.custom.primary1,
    fontWeight: 500,
  },
  link: {
    color: theme.palette.custom.primary1,
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
