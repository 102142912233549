import PropTypes from 'prop-types';
import { FormTitle } from '~common/components';
import ReviewPanel from '../registrationForm/reviewPanel';

const AgreementForm = ({
  userType,
  onSubmit,
  error,
  loading,
}) => (
  <>
    <FormTitle
      title="Review Agreement"
      image="/logo.svg"
    />
    <ReviewPanel
      userType={userType === 'parent' ? 'learner' : 'tutor'}
      allowBack={false}
      onSubmit={onSubmit}
      error={error}
      loading={loading}
    />
  </>
);

AgreementForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
  userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
};

AgreementForm.defaultProps = {
  error: null,
  loading: null,
};

export default AgreementForm;
