import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: theme.palette.custom.primary1,
    fontWeight: 500,
    fontSize: 16,
  },
}));

export default useStyles;
