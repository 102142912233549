import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import { Container, Paper } from '@mui/material';
import useStyles from './styles';

const RegistrationForm = ({
  children, imagePath,
}) => {
  const { classes } = useStyles({ imagePath });

  return (
    <Container component="main" maxWidth="md" className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={8} className={classes.form}>
            {children}
          </Grid>
          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className={classes.sidePanel} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

RegistrationForm.propTypes = {
  imagePath: PropTypes.string.isRequired,
};

export default RegistrationForm;
