import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Container, Paper } from '@mui/material';
import useStyles from './styles';
import AgreementForm from '../../components/agreementForm';
import useAgreementForm from '../../components/agreementForm/hooks/useAgreementForm';

const Agreement = ({ user }) => {
  const { classes } = useStyles({ imagePath: 'url("/writing2.png")' });
  const { onSubmit, error, loading } = useAgreementForm();

  return (
    <Container component="main" maxWidth="md" className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={8} className={classes.form}>
            <AgreementForm
              userType={user.userType}
              onSubmit={onSubmit}
              error={error}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className={classes.sidePanel} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

Agreement.propTypes = {
  user: PropTypes.shape({
    userType: PropTypes.string,
  }).isRequired,
};

export default Agreement;
