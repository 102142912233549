import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  FormContainer,
  FormTitle,
  Button,
} from '~common/components';
import SelectCard from '../../components/selectCard';
import useStyles from './styles';

const CreateAccount = () => {
  const { classes } = useStyles();
  const [path, setPath] = useState(null);
  const navigate = useNavigate();
  const createLearnerUrl = '/create-account/learner';
  const createTutorUrl = '/create-account/tutor';

  // to stop the onBlur being executed before onClick
  // enabling user to navigate to the next page
  const onMouseDown = (e) => e.preventDefault();

  const onClickHandler = (e, url) => {
    setPath(url);
    e.currentTarget.focus();
  };

  const onBlurHandler = (e) => {
    setPath(null);
    e.currentTarget.blur();
  };

  return (
    <FormContainer>
      <Box className={classes.box}>
        <FormTitle
          title="Create an account"
          description="Select your account type"
          image="/logo.svg"
          align="center"
        />
        <SelectCard
          title="Tutor Account"
          description="For Tutors or mentors"
          onClick={(e) => onClickHandler(e, createTutorUrl)}
          onBlur={onBlurHandler}
        />
        <SelectCard
          title="Learner Account"
          description="To be completed by a Parent or Guardian"
          onClick={(e) => onClickHandler(e, createLearnerUrl)}
          onBlur={onBlurHandler}
        />
      </Box>
      <div className={classes.cardNav}>
        <Button onClick={() => navigate('/')} variant="text" className={classes.link} disableRipple>
          Back
        </Button>
        <Button
          type="submit"
          disabled={!path}
          onMouseDown={onMouseDown}
          onClick={() => navigate(path)}
        >
          Next
        </Button>
      </div>
    </FormContainer>
  );
};

export default CreateAccount;
