import { useState, useRef, useEffect } from 'react';
import { API } from '@aws-amplify/api';
import dayjs from 'dayjs';
import { config } from '~common/utils';
import formFieldsTemplate from '../../../utils/registerLearnerFields.json';

// eslint-disable-next-line no-unused-vars
const formatFormDataComplete = (formData) => ({
  ...formData,
  dateOfBirth: formData?.dateOfBirth ? dayjs(formData?.dateOfBirth).toISOString() : '',
  ...((formData.learners
    ? {
      learners: formData.learners.map((learner) => (
        { ...learner, emailAddress: learner?.emailAddress?.replaceAll(' ', '')?.toLowerCase() }
      )),
    }
    : [])),
});

const formatFormDataV2 = (formData) => ({
  ...formData,
  emailAddress: formData?.emailAddress?.replaceAll(' ', '')?.toLowerCase(),
});

const useSignUpForm = ({ formFields, defaultFormStep = 0, overwriteUser = undefined }) => {
  const [formData, setFormData] = useState(formFields);
  const [formStep, setFormStep] = useState(defaultFormStep);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const learnerIdxRef = useRef(0);

  const nextFormStep = (step) => setFormStep(() => step ?? formStep + 1);
  const onPrevStep = () => setFormStep(() => formStep - 1);
  const submitOnPreviousStep = async (data) => {
    setFormData(Object.assign(formData, data));
    onPrevStep();
  };

  const onNextStep = async (data) => {
    data.userType = overwriteUser.userType;
    data.emailAddress = overwriteUser.email;
    setFormData(Object.assign(formData, data));
    nextFormStep();
  };
  const onAddAnotherLearner = (data) => {
    learnerIdxRef.current += 1;

    const newData = {
      ...data,
      learners: [
        ...data.learners,
        formFieldsTemplate.learners[0],
      ],
    };

    setFormData(newData);
    nextFormStep(3 + learnerIdxRef.current * 2);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setFormData(Object.assign(formData, data));
    const { apiName } = config;
    const path = config.path.signup;
    const myInit = {
      headers: {},
      body: {
        formData: formatFormDataV2(formData),
      },
    };
    let response;

    try {
      response = await API.post(apiName, path, myInit);
      window.location = response?.output?.agreementUrl;
    } catch (e) {
      setError('There has been an error submitting the form please contact an administrator.');
    } finally {
      setLoading(false);
    }
  };

  const onSubmitCompleteRegister = async (data) => {
    setLoading(true);
    setFormData(Object.assign(formData, data));
    const { apiName } = config;
    const path = config.path.completeRegister;
    const myInit = {
      headers: {},
      body: {
        formData: formatFormDataComplete(formData),
      },
    };
    let response;

    try {
      response = await API.post(apiName, path, myInit);
      window.location = response?.output?.agreementUrl;
    } catch (e) {
      setError('There has been an error submitting the form please contact an administrator.');
    } finally {
      setLoading(false);
    }
  };

  const onSubmitBasic = async (data) => {
    setLoading(true);
    setFormData(Object.assign(formData, data));
    const { apiName } = config;
    const path = config.path.signupBasic;
    const myInit = {
      headers: {},
      body: {
        formData: formatFormDataV2(formData),
      },
    };

    try {
      await API.post(apiName, path, myInit);
      nextFormStep();
    } catch (e) {
      setError('There has been an error submitting the form please contact an administrator.');
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    formStep,
    onNextStep,
    onPrevStep,
    submitOnPreviousStep,
    onSubmit,
    onSubmitBasic,
    onSubmitCompleteRegister,
    onAddAnotherLearner,
    learnerIdxRef,
    error,
    loading,
  };
};

export default useSignUpForm;
