import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  container: {
    margin: '16px auto auto',
    [theme.breakpoints.up('sm')]: {
      margin: '120px auto auto',
    },
  },
  form: {
    padding: '24px',
  },
  sidePanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',

    backgroundImage: props.imagePath,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    content: "''",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  paper: {
    borderRadius: 16,
  },
}));

export default useStyles;
