import PropTypes from 'prop-types';
import { FormContainer } from '~common/components';
import useForgottenPassword from './hooks/useForgottenPassword';
import ForgottenPasswordRequest from './components/forgottenPasswordRequest';
import ForgottenPasswordSubmit from './components/forgottenPasswordSubmit';
import ConfirmationPanel from './components/confirmationPanel';

const ForgottenPassword = ({ handleForgottenPassword, handleForgottenPasswordSubmit }) => {
  const {
    onSubmitForgotPasswordRequest,
    onSubmitForgotPassword,
    error,
    formStep,
    formData,
  } = useForgottenPassword({
    handleForgottenPassword,
    handleForgottenPasswordSubmit,
  });

  const conditionalComponent = () => {
    switch (formStep) {
      case 0:
        return (
          <ForgottenPasswordRequest
            onSubmit={onSubmitForgotPasswordRequest}
            error={error}
            formData={formData}
          />
        );
      case 1:
        return (
          <ForgottenPasswordSubmit
            onSubmit={onSubmitForgotPassword}
            error={error}
            formData={formData}
          />
        );
      case 2:
        return (
          <ConfirmationPanel message="Please return to " />
        );
      default:
        return (
          <ForgottenPasswordRequest onSubmit={onSubmitForgotPasswordRequest} error={error} />
        );
    }
  };

  return (
    <FormContainer maxWidth="sm">
      {conditionalComponent()}
    </FormContainer>
  );
};

ForgottenPassword.propTypes = {
  handleForgottenPassword: PropTypes.func.isRequired,
  handleForgottenPasswordSubmit: PropTypes.func.isRequired,
};

export default ForgottenPassword;
