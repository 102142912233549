import { useCallback, useMemo, useState } from 'react';

const useForm = ({
  initialValues = null,
  requiredFields = [],
}) => {
  const [values, setValues] = useState(initialValues);

  const isValid = useMemo(
    () => requiredFields.every((key) => (typeof values[key] === 'number' ? true : Boolean(values[key]))),
    [requiredFields, values],
  );

  const onChange = useCallback((key, value) => {
    setValues((formValues) => ({
      ...formValues,
      [key]: value,
    }));
  }, []);

  const passwordsMatch = useMemo(
    () => (values.password === values.confirmPassword),
    [values.password, values.confirmPassword],
  );

  return {
    values,
    isValid,
    onChange,
    passwordsMatch,
  };
};

export default useForm;
