import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  card: {
    backgroundColor: 'rgba(183, 220, 245, 0.2)',
    display: 'flex',
    padding: '24px',
    margin: '16px 0 0 0',
    width: '100%',
    height: '110px',
    textAlign: 'left',
    border: '0',
    borderRadius: '4px',
    '&:hover': {
      borderColor: '#1B7FC4',
      cursor: 'pointer',
    },
    '&:focus': {
      backgroundColor: 'rgba(183, 220, 245)',
      bordercolor: '#1B7FC4',
    },
  },
  icon: {
    color: '#FFFFFF',
    backgroundColor: '#162C5F',
    borderRadius: '100%',
    fontSize: 20,
    padding: '3px',
    marginTop: '5px',
  },
  cardText: {
    paddingLeft: '16px',

  },
  title: {
    fontWeight: '600',
    color: '#162C5F',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingBottom: '8px',
  },
}));

export default useStyles;
