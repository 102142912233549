import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';

import { Button, FormActionWrapper, FormAddressFields } from '~common/components';
import { validationSchemas } from '~common/utils';
import useStyles from './styles';

const AddressPanel = ({
  formData,
  onNextStep,
  userType,
}) => {
  const { classes } = useStyles();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm(
    {
      defaultValues: formData,
      resolver: yupResolver(validationSchemas.addressPanelSchema),
    },
  );

  return (
    <form
      onSubmit={handleSubmit(onNextStep)}
      className={classes.formPanel}
      noValidate
    >
      <FormAddressFields
        setValue={setValue}
        control={control}
        userType={userType}
        getValues={getValues}
      />
      <Grid item xs={12}>
        <FormActionWrapper>
          <div />
          <Button type="submit">Next</Button>
        </FormActionWrapper>
      </Grid>
    </form>
  );
};

AddressPanel.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  onNextStep: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

export default AddressPanel;
