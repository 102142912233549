import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox, FormControlLabel, FormGroup, Link, Alert,
} from '@mui/material';
import {
  Button,
  FormContainer,
  FormTitle,
  FormActionWrapper,
  FormTextField,
} from '~common/components';
import { validationSchemas } from '~common/utils';
import useStyles from './styles';

const UpdateNewPassword = ({ user, handleCompleteNewPassword }) => {
  const { classes } = useStyles();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (event) => {
    try {
      await handleCompleteNewPassword(user, event?.password);
    } catch (err) {
      if (err.message?.includes('expired') || err.message?.includes('user is null')) {
        setError('Your update password session has expired. Please sign in again with your temporary password');
      } else {
        setError(`Error updating password: ${err}`);
      }
    }
  };

  const { handleSubmit, control } = useForm(
    {
      defaultValues: { confirmPassword: '', password: '' }, resolver: yupResolver(validationSchemas.passwordSchema),
    },
  );

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.title}>
          <FormTitle
            title="Update Password"
            description="Type in your new password below"
            image="/logo.svg"
            align="center"
          />
        </div>
        {error && (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
        )}
        <FormTextField
          name="password"
          control={control}
          inputProps={{
            label: 'New Password',
            type: showPassword ? 'text' : 'password',
          }}
        />
        <FormTextField
          name="confirmPassword"
          control={control}
          inputProps={{
            label: 'Confirm Password',
            type: showPassword ? 'text' : 'password',
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
          }
            label="Show Password"
          />
        </FormGroup>
        <div>
          <p>This session will expire in 15mins</p>
        </div>
        <FormActionWrapper>
          <Link href="/" underline="none" className={classes.button}>Back</Link>
          <Button type="submit">Submit</Button>
        </FormActionWrapper>
      </form>
    </FormContainer>
  );
};

UpdateNewPassword.defaultProps = {
  user: {},
};

UpdateNewPassword.propTypes = {
  user: PropTypes.shape({}),
  handleCompleteNewPassword: PropTypes.func.isRequired,
};

export default UpdateNewPassword;
