import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Grid from '@mui/material/Grid';
import { Container, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormTitle,
  FormActionWrapper,
} from '~common/components';
import useAgreementForm from '../../components/agreementForm/hooks/useAgreementForm';
import useStyles from './styles';

const messagesByEvent = {
  signing_complete: {
    title: 'Thanks for signing your agreement',
    message: 'We\'re just setting up your account and you\'ll be redirected to your dashboard soon.',
    showLoading: true,
  },
  session_timeout: {
    title: 'Your session timed out',
    message: 'Use the button below to try signing your agreement again.',
    showAction: true,
  },
  cancel: {
    title: 'You still need to sign your agreement',
    message: 'Use the button below to try signing your agreement again.',
    showAction: true,
  },
  decline: {
    title: 'You declined the agreement',
    message: 'Please contact us if you would still like to access the platform.',
  },
  default: {
    title: 'Something went wrong',
    message: 'Please contact us to get support with signing your agreement.',
  },
};

const AgreementComplete = ({ user }) => {
  const { classes } = useStyles({ imagePath: 'url("/writing2.png")' });
  const { onSubmit, loading } = useAgreementForm();
  const { handleSubmit } = useForm();
  const [qs] = useSearchParams();
  const [refreshTimer, setRefreshTimer] = useState(0);
  const event = qs.get('event');
  const {
    title,
    message,
    showAction,
    showLoading,
  } = messagesByEvent[event] ?? messagesByEvent.default;

  // Repeatedly refresh the JWT. If the agreement has been signed Docusign will
  // call a webhook endpoint that will update the user state the next token to
  // be obtained will include that fact in its claims.
  useEffect(() => {
    if (user.agreement === 'signed') {
      return;
    }

    const interval = setInterval(async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      setRefreshTimer((timer) => timer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [refreshTimer]);

  return (
    <Container component="main" maxWidth="md" className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={8} className={classes.form}>
            <FormTitle
              title={title}
              image="/logo.svg"
            />
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.description}>
                {message}
              </Typography>
            </Grid>
            {showLoading ? (
              <Grid item xs={12}>
                <div className={classes.spinnerContainer}>
                  <CircularProgress />
                </div>
              </Grid>
            ) : null}
            {showAction ? (
              <Grid item xs={12}>
                { loading ? (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.formPanel}>
                      <FormActionWrapper>
                        <Button type="submit">Sign Agreement</Button>
                      </FormActionWrapper>
                    </form>
                  </Grid>
                )}
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className={classes.sidePanel} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

AgreementComplete.propTypes = {
  user: PropTypes.shape({
    agreement: PropTypes.string,
  }).isRequired,
};

export default AgreementComplete;
