import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import * as Sentry from '@sentry/react';

import { Error, NotFound } from '~common/components';
import { config, useAuth } from '~common/utils';
import { useEffect, useState } from 'react';
import VerifyEmailCard from '@patheducation/dashboard/src/components/VerifyEmailCard/index.jsx';
import RegisterTutor from '../pages/registerTutor';
import SignIn from '../pages/signin';
import UpdateNewPassword from '../pages/updateNewPassword';
import CreateAccount from '../pages/createAccount';
import RegisterLearner from '../pages/registerLearner';
import ForgottenPassword from '../pages/forgottenPassword';
import Agreement from '../pages/agreement';
import AgreementComplete from '../pages/agreementComplete';

function App() {
  const [bypassCache, setBypassCache] = useState(true);

  const {
    user,
    cognitoUser,
    loading,
    handleSignIn,
    handleCompleteNewPassword,
    handleForgottenPassword,
    handleForgottenPasswordSubmit,
  } = useAuth({ Hub, Auth, bypassCache });
  const { UserType } = config;
  let indexRoute;

  useEffect(
    () => {
      setBypassCache(false);
    },
    [],
  );

  if (user?.isAuthenticated) {
    // If the user is a parent or tutor and they have not yet signed the
    // agreement they need to do so now. Otherwise, we can go to the dashboard.
    if ('custom:notCompletedRegister' in cognitoUser.attributes
      && cognitoUser.attributes['custom:notCompletedRegister'] === 'true') {
      if (user.userType === UserType.PARENT) {
        indexRoute = (
          <Route
            index
            element={
              <RegisterLearner overwriteUser={user} defaultFormStep={2} />
}
          />
        );
      } else if (user.userType === UserType.TUTOR) {
        indexRoute = (
          <Route
            index
            element={
              <RegisterTutor overwriteUser={user} defaultFormStep={2} />
}
          />
        );
      }
    } else if (
      (user.userType === UserType.PARENT || user.userType === UserType.TUTOR)
      && (user.agreement !== undefined && user.agreement !== 'signed' && user.agreement !== 'true')
    ) {
      indexRoute = <Route index element={<Agreement user={user} />} />;
    } else {
      window.location.replace(
        user?.isAdmin
          ? import.meta.env.VITE_ADMIN_DOMAIN
          : import.meta.env.VITE_DASHBOARD_DOMAIN,
      );

      return null;
    }
  } else {
    indexRoute = <Route index element={<SignIn loading={loading} handleSignIn={handleSignIn} />} />;
  }

  const routes = (
    <Router>
      <Routes>
        {indexRoute}
        <Route
          path="/create-account"
          element={<CreateAccount />}
        />
        <Route
          path="/update-new-password"
          element={(
            <UpdateNewPassword
              user={cognitoUser}
              handleCompleteNewPassword={handleCompleteNewPassword}
            />
          )}
        />
        <Route
          path="/forgotten-password"
          element={(
            <ForgottenPassword
              handleForgottenPassword={handleForgottenPassword}
              handleForgottenPasswordSubmit={handleForgottenPasswordSubmit}
            />
          )}
        />
        <Route
          path="create-account/tutor"
          element={(
            <RegisterTutor />
          )}
        />
        <Route
          path="verify-email"
          element={(
            <VerifyEmailCard />
          )}
        />
        <Route
          path="create-account/learner"
          element={(
            <RegisterLearner />
          )}
        />
        <Route
          path="create-account/tutor/complete-signup"
          element={(
            <RegisterTutor defaultFormStep={3} />
          )}
        />
        <Route
          path="create-account/learner/complete-signup"
          element={(
            <RegisterLearner defaultFormStep={3} />
          )}
        />
        <Route
          path="create-account/learner/confirmation"
          element={(
            <RegisterLearner defaultFormStep={5} />
          )}
        />
        <Route
          path="create-account/tutor/confirmation"
          element={(
            <RegisterTutor defaultFormStep={4} />
          )}
        />
        <Route
          path="agreement"
          element={(
            <AgreementComplete user={user} />
          )}
        />
        <Route
          path="*"
          element={<NotFound isAuthenticated={user?.isAuthenticated} />}
        />
      </Routes>
    </Router>
  );

  const isDev = import.meta.env.VITE_SENTRY_ENV !== 'production'
    && import.meta.env.VITE_SENTRY_ENV !== 'staging';

  return isDev
    ? routes
    : (
      <Sentry.ErrorBoundary fallback={<Error />}>
        {routes}
      </Sentry.ErrorBoundary>
    );
}

export default App;
