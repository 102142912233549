import ReactDOM from 'react-dom/client';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { theme } from '~common/utils';
import './index.css';
import App from './router';

const isDev = import.meta.env.VITE_SENTRY_ENV !== 'production'
  && import.meta.env.VITE_SENTRY_ENV !== 'staging';

if (!isDev) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
  });
}

// Configure AWS Amplify.
Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
    cookieStorage: {
      domain: import.meta.env.VITE_COOKIE_DOMAIN,
      secure: import.meta.env.VITE_COOKIE_SECURE,
    },
  },
  API: {
    endpoints: [
      {
        name: import.meta.env.VITE_AWS_API_NAME,
        endpoint: import.meta.env.VITE_AWS_APIGATEWAY_URL,
        region: import.meta.env.VITE_AWS_REGION,
        custom_header: async () => {
          const headers = {};
          let session;

          try {
            session = await Auth.currentSession();
            headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
          } catch (error) {
            // Throws if there is no active user. We ignore the error and don't
            // set the auth header in that case.
          }

          return headers;
        },
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: import.meta.env.VITE_AWS_S3_UPLOAD_BUCKET_NAME,
      region: import.meta.env.VITE_AWS_REGION,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
