import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, FormActionWrapper } from '~common/components';
import { validationSchemas } from '~common/utils';
import useStyles from './styles';
import BackButton from '../backButton';

const config = {
  tutor: {
    agreementText: 'By signing you are agreeing to the Path Education Tutor agreement. The button below will take you to Docusign where you can review and sign it.',
    label: 'Path Education\'s Tutor Agreement *',
  },
  learner: {
    agreementText: 'By signing you are agreeing to the Path Education Learner agreement. The button below will take you to Docusign where you can review and sign it.',
    label: 'Path Education\'s Learner Agreement *',
  },
};

const ReviewPanel = ({
  formData,
  onSubmit,
  onPrevStep,
  error,
  loading,
  userType,
  allowBack,
}) => {
  const { classes } = useStyles();
  const { handleSubmit, formState: { isSubmitted } } = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchemas.agreementPanelSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.formPanel}>
      <Grid container columnSpacing={1} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.description}>
            {config[userType]?.agreementText}
          </Typography>
        </Grid>
        { error && (
          <Grid item xs={12}>
            <Alert severity="error">
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          { loading ? (
            <div className={classes.spinnerContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Grid item xs={12}>
              <FormActionWrapper>
                {allowBack ? <BackButton onClick={onPrevStep} /> : null}
                <Button type="submit" disabled={isSubmitted}>Sign Agreement</Button>
              </FormActionWrapper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

ReviewPanel.defaultProps = {
  error: null,
  loading: null,
  allowBack: true,
};

ReviewPanel.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
  userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  allowBack: PropTypes.bool,
};

export default ReviewPanel;
