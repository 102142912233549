import useStyles from './styles';

const FormActionWrapper = ({ children }) => {
  const { classes } = useStyles();

  if (!Array.isArray(children)) {
    return (
      <div className={classes.action}>
        {children}
      </div>
    );
  }

  return (
    <div className={classes.actions}>
      {children}
    </div>
  );
};

export default FormActionWrapper;
