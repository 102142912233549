import PropTypes from 'prop-types';
import { FormTitle } from '~common/components';
import { config } from '~common/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AccountPanel from '../../components/registrationForm/accountPanel';
import AddressPanel from '../../components/registrationForm/addressPanel';
import TutorInfoPanel from '../../components/registrationForm/tutorInfoPanel';
import ReviewPanel from '../../components/registrationForm/reviewPanel';
import ConfirmationPanel from '../../components/registrationForm/confirmationPanel';
import RegistrationForm from '../../components/registrationForm';
import useSignUpForm from '../../components/registrationForm/hooks/useSignUpForm';
import formFields from '../../utils/registerTutorFields.json';

export const getImagePath = (formStep) => {
  switch (formStep) {
    case 0:
    case 1:
      return 'url("/student.png")';
    case 2:
      return 'url("/writing.png")';
    case 3:
    case 4:
      return 'url("/writing2.png")';
    default:
      return 'url("/student.png")';
  }
};

const RegisterTutor = ({ defaultFormStep, overwriteUser }) => {
  const {
    formData,
    formStep,
    onNextStep,
    onPrevStep,
    onSubmitBasic,
    onSubmitCompleteRegister,
    error,
    loading,
  } = useSignUpForm({
    defaultFormStep,
    overwriteUser,
    formFields: {
      ...formFields,
      agreementVersion: config.tutorAgreementVersion,
    },
  });

  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(loc.search);
    console.log(params);
    if (params.get('event')) {
      navigate(`/agreement?event=${params.get('event')}`);
    }
  }, []);

  const conditionalComponent = () => {
    switch (formStep) {
      case 0:
        return (
          <AccountPanel
            formData={formData}
            onSubmit={onSubmitBasic}
            onNextStep={onNextStep}
          />
        );
      case 1:
        return (
          <ConfirmationPanel />
        );
      case 2:
        return (
          <AddressPanel
            formData={formData}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            userType="tutor"
          />
        );
      case 3:
        return (
          <TutorInfoPanel
            formData={formData}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
          />
        );
      case 4:
        return (
          <ReviewPanel
            formData={formData}
            onSubmit={onSubmitCompleteRegister}
            onPrevStep={onPrevStep}
            error={error}
            loading={loading}
            userType="tutor"
          />
        );
      default:
        return (
          <AccountPanel
            formData={formData}
            onSubmit={onSubmitCompleteRegister}
          />
        );
    }
  };

  const formTitle = formStep < 3 ? 'Sign up as a Tutor' : 'Review';

  return (
    <RegistrationForm imagePath={getImagePath(formStep)}>
      <FormTitle
        title={formTitle}
        image="/logo.svg"
      />
      {conditionalComponent()}
    </RegistrationForm>
  );
};

RegisterTutor.propTypes = {
  defaultFormStep: PropTypes.number,
  overwriteUser: PropTypes.shape({}),
};

RegisterTutor.defaultProps = {
  defaultFormStep: 0,
  overwriteUser: {},
};

export default RegisterTutor;
