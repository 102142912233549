import PropTypes from 'prop-types';
import { useForm, useFieldArray } from 'react-hook-form';
import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  FormTextField,
  Button,
  FormActionWrapper,
  FormAutocomplete,
} from '~common/components';
import { validationSchemas, dataLists } from '~common/utils';
import SubjectsField from '~common/components/form/formSubjectsField/index.jsx';
import useStyles from './styles';
import BackButton from '../backButton';
import { formatFieldName } from '../helpers';

const {
  schoolOptions,
  subjectOptions,
  qualificationsOptions,
  examBoardOptions,
  academicTypeOptions,
  academicValuesOptions,
} = dataLists;

const LearnerInfoPanel = ({
  formData,
  onNextStep,
  submitOnPreviousStep,
  onAddAnotherLearner,
  learnerIdxRef,
  isLastLearner,
}) => {
  const { classes } = useStyles();
  const {
    handleSubmit, control, getValues, setValue, trigger,
  } = useForm(
    { defaultValues: formData, resolver: yupResolver(validationSchemas.learnerInfoPanelSchema) },
  );

  // const { fields, remove, append } = useFieldArray({
  //   control,
  //   name: formatFieldName('subjectList', learnerIdxRef),
  // });
  // const watchSubjectList = watch(formatFieldName('subjectList', learnerIdxRef));
  // const controlledSubjectFields = fields.map((field, index) => ({
  //   ...field,
  //   ...watchSubjectList[index],
  // }));

  return (
    <form onSubmit={handleSubmit(onNextStep)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormAutocomplete
            name={formatFieldName('currentSchool', learnerIdxRef)}
            control={control}
            options={schoolOptions}
            getOptionLabel={(option) => {
              if (option === undefined) {
                return '';
              }
              return option;
            }}
            label="What school do they currently attend?"
            defaultValue={[getValues(formatFieldName('currentSchool', learnerIdxRef))]}
            inputProps={{
              label: 'Please Choose',
              type: 'search',
              required: true,
            }}
            freeSolo
            handleHomeEndKeys
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.label}>
            What Academic Year are they currently in?
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormAutocomplete
            name={formatFieldName('academicType', learnerIdxRef)}
            control={control}
            options={academicTypeOptions}
            getOptionLabel={(option) => option.value}
            defaultValue={getValues(formatFieldName('academicType', learnerIdxRef))}
            inputProps={{
              label: 'Please Choose',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutocomplete
            name={formatFieldName('academicValue', learnerIdxRef)}
            control={control}
            options={academicValuesOptions}
            getOptionLabel={(option) => option.value}
            defaultValue={getValues(formatFieldName('academicValue', learnerIdxRef))}
            inputProps={{
              label: 'Please Choose',
              required: true,
            }}
          />
        </Grid>
      </Grid>

      {/* /!* Dynamic subject fields *!/ */}
      {/* {controlledSubjectFields.map((item, index) => ( */}
      {/*   <Grid container spacing={1} key={item.id}> */}
      {/*     <Grid item xs={index === 0 ? 12 : 11} className={classes.subjectFields}> */}
      {/*       <FormAutocomplete */}
      {/*         name={formatFieldName(`.subjectList.${index}.subject`, learnerIdxRef)} */}
      {/*         defaultValue={getValues(formatFieldName(`.subjectList.${index}.subject`, learnerIdxRef))} */}
      {/*         control={control} */}
      {/*         options={subjectOptions} */}
      {/*         getOptionLabel={(option) => option.subjectName} */}
      {/*         label={index === 0 ? 'Which subjects do you require a tutor? ' : ''} */}
      {/*         inputProps={{ */}
      {/*           label: 'Subject', */}
      {/*           required: true, */}
      {/*         }} */}
      {/*       /> */}
      {/*     </Grid> */}
      {/*     /!* Prevent the user from removing all subject fields *!/ */}
      {/*     {(index > 0) && ( */}
      {/*       <Grid item xs={1}> */}
      {/*         <IconButton className={classes.deleteIcon} onClick={() => remove(index)}> */}
      {/*           <ClearIcon /> */}
      {/*         </IconButton> */}
      {/*       </Grid> */}
      {/*     )} */}
      {/*     /!* TODO: Only allow selection of listed qualification level with a matching subject. *!/ */}
      {/*     <Grid item xs={12} sm={6}> */}
      {/*       <FormAutocomplete */}
      {/*         name={formatFieldName(`.subjectList.${index}.qualificationLevel`, learnerIdxRef)} */}
      {/*         defaultValue={formatFieldName(`.subjectList.${index}.qualificationLevel`, learnerIdxRef)} */}
      {/*         control={control} */}
      {/*         options={qualificationsOptions} */}
      {/*         getOptionLabel={(option) => option.qualificationLevel} */}
      {/*         inputProps={{ */}
      {/*           label: 'Qualification Level', */}
      {/*           required: true, */}
      {/*         }} */}
      {/*       /> */}
      {/*     </Grid> */}
      {/*     <Grid item xs={12} sm={6}> */}
      {/*       <FormAutocomplete */}
      {/*         name={formatFieldName(`.subjectList.${index}.examBoard`, learnerIdxRef)} */}
      {/*         defaultValue={formatFieldName(`.subjectList.${index}.examBoard`, learnerIdxRef)} */}
      {/*         control={control} */}
      {/*         options={examBoardOptions} */}
      {/*         getOptionLabel={(option) => option.examBoardName} */}
      {/*         inputProps={{ */}
      {/*           label: 'Exam Board (Optional)', */}
      {/*         }} */}
      {/*       /> */}
      {/*     </Grid> */}
      {/*   </Grid> */}
      {/* ))} */}
      {/* <div className={classes.addButton}> */}
      {/*   <Button */}
      {/*     onClick={() => append({ */}
      {/*       subject: null, */}
      {/*       qualificationLevel: null, */}
      {/*       examBoard: null, */}
      {/*     })} */}
      {/*     variant="outlined" */}
      {/*     endIcon={<AddIcon />} */}
      {/*   > */}
      {/*     Add another Subject */}
      {/*   </Button> */}
      {/* </div> */}

      <SubjectsField
        control={control}
        getValues={getValues}
        setValue={setValue}
        trigger={trigger}
        overwriteName={formatFieldName('subjectList', learnerIdxRef)}
      />

      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <FormTextField
            name={formatFieldName('aboutYou', learnerIdxRef)}
            control={control}
            label="Any Additional information"
            inputProps={{
              multiline: true,
              rows: 7,
              margin: 'dense',
              placeholder: `Please tell us more about the learner...
      - Favourite subjects
      - Extra-curricular activities
      - Educational aspirations`,
            }}
          />
        </Grid>
      </Grid>

      {
          isLastLearner && (
          <Grid container columnSpacing={3} className={classes.addLearnerGrid}>
            <Grid item xs={12}>
              <Button onClick={handleSubmit(onAddAnotherLearner)} variant="outlined" endIcon={<AddIcon />}>
                Add another learner
              </Button>
            </Grid>
          </Grid>
          )
        }
      <FormActionWrapper>
        <BackButton onClick={handleSubmit(submitOnPreviousStep)} />
        {
          isLastLearner
          && (
          <Button onClick={handleSubmit(onAddAnotherLearner)} variant="outlined" className={classes.addLearnerButton} endIcon={<AddIcon />}>
            Add another learner
          </Button>
          )
        }
        <Button type="submit">Next</Button>
      </FormActionWrapper>
    </form>
  );
};

LearnerInfoPanel.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  onNextStep: PropTypes.func.isRequired,
  submitOnPreviousStep: PropTypes.func.isRequired,
  onAddAnotherLearner: PropTypes.func.isRequired,
  learnerIdxRef: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
  isLastLearner: PropTypes.bool.isRequired,
};

export default LearnerInfoPanel;
