import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { FormContainer, FormTitle, LoginForm } from '~common/components';
import { useForm } from '~common/utils';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function SimpleBackdrop({ show }) {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

SimpleBackdrop.propTypes = {
  show: PropTypes.bool.isRequired,
};

const SignIn = ({ handleSignIn, loading }) => {
  const navigate = useNavigate();
  const updateNewPasswordUrl = '/update-new-password';
  const [error, setError] = useState('');

  const handleFormChange = () => error && setError(null);

  const {
    values, onChange, isValid,
  } = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    requiredFields: ['email', 'password'],
  });

  const isDisabled = !isValid || Boolean(error);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await handleSignIn(
        values?.email?.replaceAll(' ', '')?.toLowerCase(),
        values?.password,
      );

      // Users who have been given a temporary password will need to set a
      // permanent one via the confirm password page.
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate(updateNewPasswordUrl);
      }
    } catch (err) {
      switch (err.name) {
        case 'UserNotConfirmedException':
          return setError('Please confirm your email address before attempting to sign in');
        case 'NotAuthorizedException':
          return setError(
            'Unable to sign in.'
            + '\r\n\r\nIf you\'re unsure about your password try resetting it using the Forgotten'
            + ' Password link above.',
          );
        default:
          setError(`Error signing in: ${err}`);
      }
    }
  };

  if (loading) {
    return <SimpleBackdrop show={loading} />;
  }

  return (
    <FormContainer>
      <LoginForm
        handleSubmit={handleSubmit}
        handleFormChange={handleFormChange}
        isDisabled={isDisabled}
        error={error}
        formTitle={(
          <FormTitle
            title="Sign in"
            description="If you already have an account"
            image="/logo.svg"
            align="center"
          />
        )}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={values?.email}
          onChange={(e) => onChange('email', e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={values?.password}
          onChange={(event) => onChange('password', event.target.value)}
        />
      </LoginForm>
    </FormContainer>
  );
};

SignIn.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SignIn;
