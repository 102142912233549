import Typography from '@mui/material/Typography';

import { Button, FormContainer, FormTitle } from '~common/components';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

const VerifyEmailCard = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <FormContainer maxWidth="md">
      <div className={classes.container}>
        <FormTitle
          title="Thank You!"
          image="/logo.svg"
          align="center"
        />
        <Typography variant="body1" className={classes.body}>
          You're all set and ready to use Path software.
        </Typography>
        <Button type="button" onClick={() => navigate('/')}>Login</Button>
      </div>
    </FormContainer>
  );
};

export default VerifyEmailCard;
