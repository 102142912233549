import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardNav: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.custom.primary1,
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 0,
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
