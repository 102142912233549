import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  panel: {
    minHeight: 100,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 32,
  },
});

export default useStyles;
