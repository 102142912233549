import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  FormAutocomplete,
  FormTextField,
  Button,
  FormActionWrapper,
  FormRadioGroup,
} from '~common/components';
import { validationSchemas, dataLists } from '~common/utils';
import { useEffect } from 'react';
import useStyles from './styles';
import BackButton from '../backButton';
import { formatFieldName } from '../helpers';

const {
  countryCodeOptions,
} = dataLists;

const LearnerAccountPanel = ({
  formData,
  onNextStep,
  submitOnPreviousStep,
  learnerIdxRef,
}) => {
  const { classes } = useStyles();

  const learnerEmailAddresses = formData?.learners.map((learner) => learner.emailAddress);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchemas.learnerAccountPanelSchema),
    context: {
      parentEmailAddress: formData?.emailAddress,
      learnerEmailAddresses,
    },
  });

  useEffect(() => {
    if (getValues(formatFieldName('phoneNumberPrefix', learnerIdxRef)) === null) {
      setValue(formatFieldName('phoneNumberPrefix', learnerIdxRef), {
        id: 'UK',
        value: '+44',
        name: 'United Kingdom',
      });
    }
  }, []);

  // TODO: Work around. Currently if the user creates an additional learner and navigates backwards
  // from this page, without having first completed the LearnerInfoPanel panel, they will be stuck
  // without being able to navigate forwards. So we check that one of the LearnerInfoPanel fields
  // has been submitted before allowing backwards navigation.
  // The reason this occurs is because on submit every learner in the formData is currently
  // run against validation. The best place to handle this is in the yup validation rules.
  const isBackButtonDisabled = getValues(formatFieldName('academicYear', learnerIdxRef)) === null;

  return (
    <form onSubmit={handleSubmit(onNextStep)} className={classes.formPanel} noValidate>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name={formatFieldName('firstName', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner First Name',
              autoFocus: true,
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name={formatFieldName('lastName', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner Last Name',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name={formatFieldName('emailAddress', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner Email Address',
              required: true,
              className: classes.emailInput,
            }}
          />
        </Grid>
        <Grid item xs={5} sm={4}>
          <FormAutocomplete
            name={formatFieldName('phoneNumberPrefix', learnerIdxRef)}
            control={control}
            className={classes.prefix}
            options={countryCodeOptions}
            disableClearable
            getOptionLabel={(option) => (option ? option.value : '')}
            renderOption={(props, option) => (
              <li {...props} key={option ? option.id : ''}>
                {option ? option.name : ''}
              </li>
            )}
            defaultValue={getValues(formatFieldName('phoneNumberPrefix', learnerIdxRef))}
            inputProps={{
              placeholder: '+44',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={7} sm={8}>
          <FormTextField
            name={formatFieldName('phoneNumber', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Number',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            control={control}
            name={formatFieldName('ownAccount', learnerIdxRef)}
            label="Would you like the student to have access to their own account using their own log in details?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormActionWrapper>
            <BackButton
              onClick={handleSubmit(submitOnPreviousStep)}
              disabled={isBackButtonDisabled}
            />
            <Button type="submit">Next</Button>
          </FormActionWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

LearnerAccountPanel.propTypes = {
  formData: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    learners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onNextStep: PropTypes.func.isRequired,
  submitOnPreviousStep: PropTypes.func.isRequired,
  learnerIdxRef: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
};

export default LearnerAccountPanel;
