import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  formPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  prefix: {
    marginTop: 16,
  },
  emailInput: {
    '& .MuiInputBase-input': {
      textTransform: 'lowercase',
    },
  },
});

export default useStyles;
