import PropTypes from 'prop-types';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { Typography } from '@mui/material';

import useStyles from './styles';

const SelectCard = ({
  title, description, onClick, onBlur,
}) => {
  const { classes } = useStyles();
  return (
    <button type="button" className={classes.card} onClick={onClick} onBlur={onBlur}>
      <LocalLibraryOutlinedIcon className={classes.icon} />
      <div className={classes.cardText}>
        <Typography variant="body1" className={classes.title}>
          { title }
        </Typography>
        <Typography variant="body2" className={classes.description}>
          { description }
        </Typography>
      </div>
    </button>
  );
};

SelectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default SelectCard;
