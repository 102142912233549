import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { Button } from '~common/components';
import useStyles from './styles';

const ConfirmationPanel = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Grid container columnSpacing={1} className={classes.panel}>
      <Grid item xs={12}>
        <Alert severity="info">
          You will shortly receive a link by e-mail which you will need to click <strong>within 24 Hours</strong> to confirm your account. You will need to confirm your email address before being able to sign-in. If you do not receive the e-mail, please check your &apos;Spam&apos; or &apos;Junk E-mail&apos; or get in touch with us at <Link href="mailto:support@patheducation.co.uk">support@patheducation.co.uk</Link>
        </Alert>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button variant="text" onClick={() => navigate('/')}>Sign in</Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmationPanel;
