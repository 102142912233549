import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  subjectFields: {
    marginTop: 24,
  },
  addButton: {
    paddingTop: 8,
  },
  deleteIcon: {
    marginTop: 32,
  },
  addLearnerGrid: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: 16,
    },
  },
  addLearnerButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  label: {
    paddingBottom: 8,
    color: '#162C5F',
    fontWeight: 500,
    fontSize: 16,
  },
}));
